"use strict";
exports.__esModule = true;
exports.LikesMap = exports.LikesHandler = void 0;
window.onload = function () {
    new LikesHandler();
};
var LikesHandler = /** @class */ (function () {
    function LikesHandler() {
        var _this = this;
        this.loadLikes = function () {
            var likesMap = LikesHandler.getLikesMap();
            if (likesMap == null) {
                window.localStorage.setItem("likes", JSON.stringify(new LikesMap(new Map([]))));
            }
        };
        this.likesButtonAction = function () {
            var likeButtons = document.getElementsByClassName("like-button");
            Array.prototype.forEach.call(likeButtons, function (likeButton) {
                if (LikesHandler.getLikesMap().ids[likeButton.dataset.id]) {
                    likeButton.className = 'like-button like-button-full-heart';
                }
                likeButton.addEventListener('click', function () {
                    var likesMap = LikesHandler.getLikesMap();
                    if (likeButton.className.includes("empty")) {
                        likesMap.ids[likeButton.dataset.id] = true;
                        _this.updateLikesMap(likesMap);
                        likeButton.className = 'like-button-resize like-button like-button-full-heart';
                    }
                    else {
                        likesMap.ids[likeButton.dataset.id] = false;
                        _this.updateLikesMap(likesMap);
                        likeButton.className = 'like-button-resize like-button like-button-empty-heart';
                    }
                });
            });
        };
        this.updateLikesMap = function (likesMap) {
            window.localStorage.setItem("likes", JSON.stringify(likesMap));
        };
        this.loadLikes();
        this.likesButtonAction();
    }
    LikesHandler.getLikesMap = function () {
        return JSON.parse(window.localStorage.getItem("likes"));
    };
    return LikesHandler;
}());
exports.LikesHandler = LikesHandler;
var LikesMap = /** @class */ (function () {
    function LikesMap(ids) {
        this.ids = ids;
    }
    return LikesMap;
}());
exports.LikesMap = LikesMap;

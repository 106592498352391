"use strict";
exports.__esModule = true;
exports.Slider = void 0;
var Slider = /** @class */ (function () {
    function Slider(type, maxValue, range, byname) {
        var _this = this;
        this.reload = function () {
            _this.fillSlider('#C6C6C6', '#35477D');
            _this.controlFromSlider();
            _this.controlToSlider();
        };
        this.controlFromSlider = function () {
            var _a = _this.getParsed(), from = _a[0], to = _a[1];
            var difference = to - from;
            if (difference <= _this.range) {
                _this.fromSlider.value = (to - _this.range).toString();
            }
            _this.fillSlider('#C6C6C6', '#35477D');
        };
        this.controlToSlider = function () {
            var _a = _this.getParsed(), from = _a[0], to = _a[1];
            var difference = to - from;
            if (from < to && difference >= _this.range) {
                _this.toSlider.value = to.toString();
            }
            else {
                _this.toSlider.value = (from + _this.range).toString();
            }
            _this.fillSlider('#C6C6C6', '#35477D');
        };
        this.getParsed = function () {
            var from = parseInt(_this.fromSlider.value, 10);
            var to = parseInt(_this.toSlider.value, 10);
            return [from, to];
        };
        this.fillSlider = function (sliderColor, rangeColor) {
            var minFilter = document.getElementById(_this.type + "-min-filter");
            var maxFilter = document.getElementById(_this.type + "-max-filter");
            var rangeDistance = parseInt(_this.toSlider.max) - parseInt(_this.toSlider.min);
            var fromPosition = parseInt(_this.fromSlider.value) - parseInt(_this.toSlider.min);
            var toPosition = parseInt(_this.toSlider.value) - parseInt(_this.toSlider.min);
            maxFilter.textContent = _this.formattedToPrice(_this.toSlider.value);
            minFilter.textContent = _this.formattedToPrice(_this.fromSlider.value);
            _this.toSlider.style.background = "linear-gradient(\n              to right,\n              ".concat(sliderColor, " 0%,\n              ").concat(sliderColor, " ").concat((fromPosition) / (rangeDistance) * 100, "%,\n              ").concat(rangeColor, " ").concat(((fromPosition) / (rangeDistance)) * 100, "%,\n              ").concat(rangeColor, " ").concat((toPosition) / (rangeDistance) * 100, "%, \n              ").concat(sliderColor, " ").concat((toPosition) / (rangeDistance) * 100, "%, \n              ").concat(sliderColor, " 100%)");
        };
        this.formattedToPrice = function (number) {
            var formattedNumber = new Intl.NumberFormat().format(parseInt(number));
            if (formattedNumber === "150.000") {
                return "+150.000" + _this.byname;
            }
            else {
                return formattedNumber + _this.byname;
            }
        };
        this.loadPriceSlider = function () {
            _this.fillSlider('#C6C6C6', '#35477D');
            _this.fromSlider.oninput = function () { return _this.controlFromSlider(); };
            _this.toSlider.oninput = function () { return _this.controlToSlider(); };
        };
        this.type = type;
        this.maxValue = maxValue;
        this.range = range;
        this.byname = byname;
        this.fromSlider = document.getElementById(this.type + '-from-slider');
        this.toSlider = document.getElementById(this.type + '-to-slider');
        this.loadPriceSlider();
    }
    return Slider;
}());
exports.Slider = Slider;

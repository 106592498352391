"use strict";
exports.__esModule = true;
var splide_1 = require("@splidejs/splide");
require("@splidejs/splide/css/sea-green");
var likesHandler_1 = require("./likesHandler");
var footer_1 = require("./footer");
var contact_1 = require("./contact");
var cookiesHandler_1 = require("./cookiesHandler");
var filtersHandler_1 = require("./filtersHandler");
var whatsappHelper_1 = require("./whatsappHelper");
var L = require('leaflet');
window.onload = function () {
    new Index();
};
var Index = /** @class */ (function () {
    function Index() {
        var _this = this;
        this.loadNavbarImagesCarousel = function () {
            new splide_1["default"](".navbar-images-carousel", {
                type: 'loop',
                arrows: false,
                drag: false,
                pagination: true,
                autoplay: true,
                autoScroll: {
                    speed: 6
                }
            }).mount();
        };
        this.loadSplideReviews = function () {
            var featuredAdsImages = document.getElementsByClassName("featured-ad-splide-images");
            Array.prototype.forEach.call(featuredAdsImages, function (element) {
                new splide_1["default"]("." + element.classList.item(2), {
                    arrows: true,
                    drag: true,
                    pagination: true,
                    role: ""
                }).mount();
            });
            var arrows = document.getElementsByClassName("splide__arrow");
            Array.prototype.forEach.call(arrows, function (element) {
                element.addEventListener('click', function (event) {
                    event.preventDefault();
                });
            });
            var reviewsSplide = new splide_1["default"](".review-comments", {
                type: 'loop',
                drag: 'free',
                focus: 'center',
                arrows: false,
                autoplay: true,
                padding: '5rem',
                perPage: _this.getReviewsPerPage(),
                autoScroll: {
                    speed: 6
                }
            }).mount();
            addEventListener("resize", function (event) {
                if (window.innerWidth < 768 && reviewsSplide.options.perPage != 1) {
                    reviewsSplide.options.perPage = _this.getReviewsPerPage();
                    reviewsSplide.refresh();
                }
                else if (window.innerWidth > 768 && window.innerWidth < 1200 && reviewsSplide.options.perPage != 3) {
                    reviewsSplide.options.perPage = _this.getReviewsPerPage();
                    reviewsSplide.refresh();
                }
                else if (window.innerWidth > 1200 && reviewsSplide.options.perPage != 5) {
                    reviewsSplide.options.perPage = _this.getReviewsPerPage();
                    reviewsSplide.refresh();
                }
            });
        };
        this.getReviewsPerPage = function () {
            if (window.innerWidth < 768) {
                return 1;
            }
            else if (window.innerWidth > 768 && window.innerWidth < 1200) {
                return 3;
            }
            else {
                return 5;
            }
        };
        this.myFavouritesCall = function () {
            var myFavouritesButtons = document.getElementsByClassName("my-favourites-button");
            Array.prototype.forEach.call(myFavouritesButtons, function (element) {
                element.addEventListener('click', function () {
                    var likesIds = [];
                    for (var key in likesHandler_1.LikesHandler.getLikesMap().ids) {
                        if (likesHandler_1.LikesHandler.getLikesMap().ids[key]) {
                            likesIds.push(key);
                        }
                    }
                    window.location.href = "/myFavourites?ids=" + likesIds.toString();
                });
            });
        };
        this.navbarMenu = function () {
            var navbarMenuButton = document.getElementById("navbar-menu-button");
            var navbarSubmenu = document.getElementById("navbar-submenu");
            window.onscroll = function () {
                navbarSubmenu.style.display = 'none';
            };
            navbarMenuButton.addEventListener("click", function () {
                if (navbarSubmenu.style.display === 'flex') {
                    navbarSubmenu.style.display = 'none';
                }
                else {
                    navbarSubmenu.style.display = 'flex';
                }
            });
            document.addEventListener("click", function (event) {
                if (event.target instanceof HTMLElement
                    && !(event.target.closest('.navbar-submenu') || event.target.closest('.navbar-menu-button')) && navbarSubmenu.style.display == 'flex') {
                    navbarSubmenu.style.display = 'none';
                }
            }, false);
        };
        this.map = function () {
            var map = L.map('map', {});
            var greenIcon = L.icon({
                iconUrl: './../assets/cyc-pin.png',
                iconSize: [100, 100],
                iconAnchor: [50, 100]
            });
            map.setView([39.00143252529151, -3.3707274803264955], 15);
            L.marker([39.00143252529151, -3.3707274803264955], { icon: greenIcon })
                .addTo(map);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19
            }).addTo(map);
        };
        new contact_1.Contact();
        this.navbarMenu();
        this.map();
        new filtersHandler_1.FiltersHandler();
        new likesHandler_1.LikesHandler();
        new cookiesHandler_1.CookiesHandler();
        new footer_1.Footer();
        this.myFavouritesCall();
        this.loadSplideReviews();
        this.loadNavbarImagesCarousel();
        new whatsappHelper_1.WhatsappHelper();
        new Animations();
        Array.prototype.forEach.call(document.querySelectorAll('.splide__slide[role$="tabpanel"]'), function (element) {
            element.removeAttribute("role");
        });
    }
    return Index;
}());
var Animations = /** @class */ (function () {
    function Animations() {
        var _this = this;
        this.addFadeIn = function () {
            var featuredAdsCards = document.getElementsByClassName("featured-ad-card");
            Array.prototype.forEach.call(featuredAdsCards, function (element, index) {
                var styleWithRowOrder = "";
                if ((index + 1) % 3 == 1) {
                    styleWithRowOrder = "first";
                }
                else if ((index + 1) % 3 == 2) {
                    styleWithRowOrder = "second";
                }
                else {
                    styleWithRowOrder = "third";
                }
                var isElementInView = mustHaveAnimation(element);
                if (isElementInView) {
                    element.className = "featured-ad-card fade-in-right-" + styleWithRowOrder;
                }
            });
            function mustHaveAnimation(element) {
                var pageTop = document.documentElement["scrollTop"] - 100;
                var pageBottom = pageTop + window.innerHeight;
                var elementTop = element.offsetTop;
                var elementBottom = elementTop + element.offsetHeight;
                return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
            }
        };
        if (window.innerWidth > 760) {
            this.addFadeIn();
        }
        window.onscroll = function () { return _this.addFadeIn(); };
    }
    return Animations;
}());

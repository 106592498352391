"use strict";
exports.__esModule = true;
exports.CookiesHandler = void 0;
require("@splidejs/splide/css/sea-green");
window.onload = function () {
};
var CookiesHandler = /** @class */ (function () {
    function CookiesHandler() {
        var _this = this;
        this.cookiesModal = document.getElementById("cookies-modal");
        this.init = function () {
            if (_this.checkIfCookieModalShouldBeShown()) {
                _this.cookiesModal.style.display = "block";
            }
            else if (window.localStorage.getItem("cookiesIfAccept") === "0") {
                _this.removeCookies();
            }
            _this.acceptCookies();
            _this.rejectCookies();
            _this.addCookieForCookies();
        };
        this.checkIfCookieModalShouldBeShown = function () {
            var cookiesStatus = window.localStorage.getItem("cookiesIfAccept");
            return cookiesStatus == null;
        };
        this.acceptCookies = function () {
            var acceptCookiesButton = document.getElementById("accept-cookies");
            acceptCookiesButton.addEventListener('click', function () {
                window.localStorage.setItem("cookiesIfAccept", "1");
                _this.cookiesModal.style.display = "none";
                _this.addCookieForCookies();
                window.location.reload();
            });
        };
        this.rejectCookies = function () {
            var acceptCookiesButton = document.getElementById("reject-cookies");
            acceptCookiesButton.addEventListener('click', function () {
                window.localStorage.setItem("cookiesIfAccept", "0");
                _this.cookiesModal.style.display = "none";
                _this.removeCookies();
                window.location.reload();
            });
        };
        this.removeCookies = function () {
            var Cookies = document.cookie.split(';');
            for (var i = 0; i < Cookies.length; i++) {
                document.cookie = Cookies[i] + "=; expires=" + new Date(0).toUTCString();
            }
            _this.addCookieForCookies();
        };
        this.addCookieForCookies = function () {
            var todayDate = new Date();
            todayDate.setDate(todayDate.getDate() + 365);
            document.cookie = 'cookies-flag=true; expires=' + todayDate + '; path=/';
        };
        this.init();
    }
    return CookiesHandler;
}());
exports.CookiesHandler = CookiesHandler;

"use strict";
exports.__esModule = true;
exports.WhatsappHelper = void 0;
var WhatsappHelper = /** @class */ (function () {
    function WhatsappHelper() {
        this.whatsappHelpButton = function () {
            var whatsappHelperButton = document.getElementById("whatsapp-help");
            if (whatsappHelperButton != null) {
                whatsappHelperButton.addEventListener('click', function () {
                    window.open("https://wa.me/+34652834126?text=\u00A1Hola! Estoy interesado en contactar con vosotros.", '_blank');
                });
            }
        };
        this.whatsappHelpButton();
    }
    return WhatsappHelper;
}());
exports.WhatsappHelper = WhatsappHelper;

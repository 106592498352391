"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.FiltersHandler = void 0;
var Slider_1 = require("./Slider");
var FiltersHandler = /** @class */ (function () {
    function FiltersHandler() {
        var _this = this;
        this.zonesMapSelected = new Map();
        this.zoneMultiSelectHandler = function () {
            Array.prototype.forEach.call(_this.zoneFilter.children, function (item) {
                _this.zonesMapSelected.set(item.dataset["value"], false);
                item.addEventListener('click', function (element) {
                    var optionClicked = element.target;
                    if (optionClicked.dataset["value"] === "all") {
                        Array.prototype.forEach.call(_this.zoneFilter.children, function (item) {
                            if (item.dataset["value"] === "all") {
                                item.dataset["selected"] = "true";
                                _this.zonesMapSelected.set(item.dataset["value"], true);
                                item.classList.add("check");
                            }
                            else {
                                item.dataset["selected"] = "false";
                                _this.zonesMapSelected.set(item.dataset["value"], false);
                                if (item.nodeName !== "#text") {
                                    item.classList.remove("check");
                                }
                            }
                        });
                    }
                    else {
                        _this.zonesMapSelected.set("all", false);
                        _this.zonesMapSelected.set(optionClicked.dataset["value"], !_this.zonesMapSelected.get(optionClicked.dataset["value"]));
                        Array.prototype.forEach.call(_this.zoneFilter.children, function (item) {
                            item.classList.remove("check");
                        });
                        if (!_this.zonesMapSelected.get(optionClicked.dataset["value"])) {
                            optionClicked.classList.remove("check");
                        }
                        Array.prototype.forEach.call(_this.zoneFilter.children, function (item) {
                            item.dataset["selected"] = _this.zonesMapSelected.get(item.dataset["value"]) ? "true" : "false";
                            if (_this.zonesMapSelected.get(item.dataset["value"])) {
                                item.classList.add("check");
                            }
                        });
                    }
                    if (!Array.from(_this.zonesMapSelected.values()).includes(true)) {
                        _this.zoneFilter.childNodes.forEach(function (item) {
                            item.dataset["selected"] = (item.dataset["value"] === "all") + "";
                            _this.zonesMapSelected.set(item.dataset["value"], item.dataset["value"] === "all");
                            if (item.dataset["value"] === "all") {
                                item.classList.remove("check");
                            }
                        });
                    }
                });
            });
        };
        this.searchWithReference = function () {
            _this.filterSearchWithReferenceButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.redirectToSearchWithReference()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        this.redirectToSearchWithReference = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, responseResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch('/searchWithReference', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json;charset=UTF-8'
                            },
                            body: '{referenceId: "' + this.filterSearchWithReference.value + '"}'
                        })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.text()];
                    case 2:
                        responseResult = _a.sent();
                        if (responseResult != "NotFound") {
                            this.referenceErrorMessage.style.display = "none";
                            window.location.href = "/detalle/inmueble/" + responseResult;
                        }
                        else {
                            this.referenceErrorMessage.style.display = "block";
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.adjustFilters = function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var searchParamsMap = window.location.search
                .replace("?", "")
                .split("&")
                .map(function (key) { return key.split("="); });
            var paramsMap = new Map(searchParamsMap.map(function (obj) { return [obj[0], obj[1]]; }));
            _this.populationFilter.value = (_a = paramsMap.get("population")) !== null && _a !== void 0 ? _a : "all";
            _this.adjustZone((_b = paramsMap.get("zone")) !== null && _b !== void 0 ? _b : "all");
            _this.operationTypeFilter.value = (_c = paramsMap.get("operationType")) !== null && _c !== void 0 ? _c : "all";
            _this.propertyTypeFilter.value = (_d = paramsMap.get("propertyType")) !== null && _d !== void 0 ? _d : "all";
            _this.minPriceFilter.value = (_e = paramsMap.get("minPriceFilter")) !== null && _e !== void 0 ? _e : "0";
            _this.maxPriceFilter.setAttribute("value", (_f = paramsMap.get("maxPriceFilter")) !== null && _f !== void 0 ? _f : _this.maxPriceFilter.max);
            _this.minMetersFilter.value = (_g = paramsMap.get("minMetersFilter")) !== null && _g !== void 0 ? _g : "0";
            _this.maxMetersFilter.value = (_h = paramsMap.get("maxMetersFilter")) !== null && _h !== void 0 ? _h : _this.maxMetersFilter.max;
            _this.bathroomsMinFilter.value = (_j = paramsMap.get("minBathroomsFilter")) !== null && _j !== void 0 ? _j : "0";
            _this.bathroomsMaxFilter.value = (_k = paramsMap.get("maxBathroomsFilter")) !== null && _k !== void 0 ? _k : _this.bathroomsMaxFilter.max;
            _this.bedRoomsMinFilter.value = (_l = paramsMap.get("minBedroomsFilter")) !== null && _l !== void 0 ? _l : "0";
            _this.bedroomsMaxFilter.value = (_m = paramsMap.get("maxBedroomsFilter")) !== null && _m !== void 0 ? _m : _this.bedroomsMaxFilter.max;
            _this.priceSlider.reload();
            _this.metersSlider.reload();
            _this.bedroomsSlider.reload();
            _this.bathroomsSlider.reload();
            _this.checkZoneVisibility();
        };
        this.adjustZone = function (zoneParam) {
            Array.prototype.forEach.call(_this.zoneFilter.children, function (option) {
                if (decodeURIComponent(zoneParam).includes(option.dataset["value"])) {
                    option.classList.add("check");
                    _this.zonesMapSelected.set(option.dataset["value"], true);
                    option.dataset["selected"] = "true";
                }
            });
        };
        this.searchWithFilters = function () {
            _this.searchButton.addEventListener('click', function () {
                _this.redirectToEstateResults();
            });
        };
        this.redirectToEstateResults = function () {
            var selectedZones = [];
            _this.zonesMapSelected.forEach(function (enabled, zone) {
                if (enabled) {
                    selectedZones.push(zone);
                }
            });
            window.location.href = "/estateResults?population=" + _this.populationFilter.value
                + "&zone=" + selectedZones
                + "&operationType=" + _this.operationTypeFilter.value
                + "&propertyType=" + _this.propertyTypeFilter.value
                + "&minPriceFilter=" + _this.cleanParam(_this.minPriceFilter.value)
                + "&maxPriceFilter=" + _this.cleanParam(_this.maxPriceFilter.value)
                + "&minMetersFilter=" + _this.cleanParam(_this.minMetersFilter.value)
                + "&maxMetersFilter=" + _this.cleanParam(_this.maxMetersFilter.value)
                + "&minBathroomsFilter=" + _this.cleanParam(_this.bathroomsMinFilter.value)
                + "&maxBathroomsFilter=" + _this.cleanParam(_this.bathroomsMaxFilter.value)
                + "&minBedroomsFilter=" + _this.cleanParam(_this.bedRoomsMinFilter.value)
                + "&maxBedroomsFilter=" + _this.cleanParam(_this.bedroomsMaxFilter.value);
        };
        this.filtersZoneHandler = function () {
            var populationFilter = document.getElementById("population");
            populationFilter.onchange = function () {
                _this.checkZoneVisibility();
            };
        };
        this.checkZoneVisibility = function () {
            var populationFilter = document.getElementById("population");
            var zoneFilterWrapper = document.getElementById("filter-zone");
            if (populationFilter.value === "manzanares") {
                zoneFilterWrapper.style.display = "flex";
            }
            else {
                zoneFilterWrapper.style.display = "none";
            }
        };
        this.cleanParam = function (paramText) {
            return paramText
                .replace("€", "")
                .replace("m²", "")
                .replace("bañ", "")
                .replace("hab", "")
                .replace(".", "")
                .replace("+", "")
                .replace(" ", "");
        };
        this.searchButton = document.getElementById("search-button");
        this.populationFilter = document.getElementById("population");
        this.zoneFilter = document.getElementById("zones");
        this.operationTypeFilter = document.getElementById("operation_type");
        this.propertyTypeFilter = document.getElementById("property_type");
        this.minPriceFilter = document.getElementById("price-from-slider");
        this.maxPriceFilter = document.getElementById("price-to-slider");
        this.minMetersFilter = document.getElementById("meters-from-slider");
        this.maxMetersFilter = document.getElementById("meters-to-slider");
        this.bathroomsMinFilter = document.getElementById("bathrooms-from-slider");
        this.bathroomsMaxFilter = document.getElementById("bathrooms-to-slider");
        this.bedRoomsMinFilter = document.getElementById("bedrooms-from-slider");
        this.bedroomsMaxFilter = document.getElementById("bedrooms-to-slider");
        this.filterSearchWithReferenceButton = document.getElementById("filter-search-with-reference-button");
        this.filterSearchWithReference = document.getElementById("filter-search-with-reference");
        this.searchWithFilters();
        this.filtersZoneHandler();
        this.priceSlider = new Slider_1.Slider('price', 150000, 10000, "€");
        this.metersSlider = new Slider_1.Slider('meters', 300, 10, "m²");
        this.bedroomsSlider = new Slider_1.Slider('bedrooms', 3, 1, " hab");
        this.bathroomsSlider = new Slider_1.Slider('bathrooms', 3, 1, " bañ");
        this.referenceErrorMessage = document.getElementById("reference-error");
        this.referenceErrorMessage.style.display = 'none';
        this.zoneMultiSelectHandler();
        this.adjustFilters();
        this.captureEnterKey();
        this.searchWithReference();
    }
    FiltersHandler.prototype.captureEnterKey = function () {
        var _this = this;
        window.onkeydown = function (event) {
            if (event.key == 'Enter') {
                if (_this.filterSearchWithReference.value === "") {
                    _this.redirectToEstateResults();
                }
                else {
                    _this.redirectToSearchWithReference();
                }
            }
        };
    };
    return FiltersHandler;
}());
exports.FiltersHandler = FiltersHandler;

"use strict";
exports.__esModule = true;
exports.Footer = void 0;
var Footer = /** @class */ (function () {
    function Footer() {
        this.getYear = function () {
            document.getElementById("footer-year").innerHTML = "©" + new Date().getFullYear();
        };
        this.getYear();
    }
    return Footer;
}());
exports.Footer = Footer;
